.authContainer {
  display: flex;
  width: 100%;
  padding: 2rem 10rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1800px;
  min-height: 100vh;
  align-items: center;
  gap: 3rem;
}

.authColumn {
  width: 50%;
  padding: 15px;
}

.authHeader {
  padding-bottom: 2rem;
}

.authHeader.center {
  text-align: center;
}

.authHeader h1 {
  font-size: 2.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.authHeader p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
}

.authHeader p span {
  opacity: 0.7;
}

.authHeader img {
  width: 100%;
  max-height: 300px;
  object-fit: contain;
  object-position: center center;
}

.authFormContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 1.5rem;
}

.authFormContainer button {
  margin-top: 2rem;
}

.backToHome {
  padding-bottom: 2rem;
}

.backToHome a {
  display: flex;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  align-items: center;
  gap: 4px;
}

.passwordRequirements {
  font-size: 1rem;
  font-weight: 300;
  opacity: 0.9;
}

.passwordRequirements ul {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
}

.passwordRequirements ul li {
  color: black;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.passwordRequirements ul li.invalidPassword {
  color: var(--errorColor);
}

.disclaimer {
  font-size: 0.85rem;
  font-weight: 300;
  text-align: center;
  color: #a7a7a7;
  margin-top: 1rem;
}

.disclaimer a {
  font-weight: 500;
  color: #808080;
}

.otpContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

.otpContainer .otpInputContainer {
  height: 75px;
  width: 280px;
}

.otpContainer .otpText {
  font-size: 2rem;
  line-height: 4rem;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}

.otpContainer .inactiveText {
  background-color: #ececec;
}

.otpContainer .selectedText {
  outline: solid 1px var(--infoColor);
  color: var(--infoColor);
}

@media screen and (max-width: 1200px) {
  .authContainer {
    padding: 2rem 3rem;
    max-width: none;
    gap: 0;
  }
}

@media screen and (max-width: 900px) {
  .authContainer {
    flex-direction: column;
    justify-content: center;
  }

  .authColumn {
    width: 100%;
  }

  .authColumn:not(:first-child) {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .authContainer {
    min-height: 100vh;
    padding: 7rem 2rem 4rem;
  }

  .authColumn {
    padding: 0;
  }

  .backToHome {
    padding-bottom: 0.5rem;
  }

  .authHeader h1 {
    font-size: 2rem;
  }

  .authFormContainer a.linkAlt {
    margin-bottom: 1.5rem;
  }

  .otpContainer .otpInputContainer {
    width: 270px;
  }

  .otpContainer .otpText {
    font-size: 1.8rem;
  }
}
