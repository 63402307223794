.fieldContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 1.5rem;
  align-items: center;
  flex-wrap: wrap;
}

.fieldContainer.halfWidth {
  width: 50%;
  padding-right: 1rem;
}

.fieldContainer .searchIcon {
  display: flex;
  position: absolute;
  padding-left: 1rem;
}

.fieldContainer .searchIcon svg {
  width: 1.5rem;
  height: 1.5rem;
  color: #858585;
}

.fieldContainer .fieldTextGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0.25rem;
}

.fieldContainer .fieldTextGroup.textAlignment {
  padding-bottom: 20px;
}

.fieldContainer .fieldTextGroup .fieldLabel {
  font-size: 1rem;
  font-weight: 400;
  color: black;
}

.fieldContainer .fieldTextGroup .helpText {
  font-size: 0.9rem;
  font-weight: 300;
  color: #757575;
}

.fieldContainer .fieldTextGroup .fieldLabel.optional span {
  font-weight: 300;
  color: #575757;
  padding-left: 0.5rem;
}

.fieldContainer .fieldInputGroup {
  display: flex;
  align-items: center;
  width: 100%;
}

.fieldContainer .fieldInputGroup .fieldInput {
  font-family: "quiet-sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 300;
  color: black;
  background: transparent;
  padding: 0.625rem 1rem;
  width: 100%;
  border: solid 1px rgba(46, 48, 53, 0.2);
  border-radius: 0.5rem;
  outline: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.fieldContainer .fieldInputGroup .fieldInput.textarea {
  min-height: 100px;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  resize: vertical;
}

.fieldContainer .fieldInputGroup .inputPrefix,
.fieldContainer .fieldInputGroup .inputPostfix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.1rem;
  font-weight: 300;
  padding: 0.625rem 0.5rem;
  color: #4b4b4b;
  background: #e0e0e0;
  border: solid 1px rgba(46, 48, 53, 0.2);
}

.fieldContainer .fieldInputGroup .inputPrefix {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  border-right-width: 0;
}

.fieldContainer .fieldInputGroup .inputPostfix {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-left-width: 0;
}

.fieldContainer .fieldInputGroup .fieldInput.prefix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.fieldContainer .fieldInputGroup .fieldInput.postfix {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.fieldContainer .fieldInputGroup .fieldInput.searchField {
  padding-left: 3rem;
}

.fieldContainer .fieldInputGroup .fieldInput.password {
  padding-right: 2.5rem;
}

.fieldContainer .fieldInputGroup.shortField {
  width: 40%;
}

.fieldContainer .fieldInputGroup.orderRadioInputGroup {
  gap: 1rem;
}

.fieldContainer .fieldInputGroup .fieldInput:focus {
  border-color: var(--infoColor);
}

.fieldContainer .fieldInputGroup .fieldInput.disabled {
  background-color: #efefef;
  color: #858585;
}

.fieldContainer .fieldInputGroup .fieldInput.disabled::placeholder {
  color: #c0c0c0;
}

.fieldContainer .fieldInputGroup .fieldInput.invalid {
  border-color: var(--errorColor);
}

.fieldContainer .passwordIcon {
  display: flex;
  font-size: 1.5rem;
  color: black;
  margin-left: -2.5rem;
  cursor: pointer;
}

.fieldContainer .fieldErrorMsg {
  font-size: 0.9rem;
  font-weight: 300;
  color: var(--errorColor);
  width: 100%;
}

@media screen and (max-width: 992px) {
  .fieldContainer .fieldInputGroup.shortField {
    width: 60%;
  }
}

@media screen and (max-width: 768px) {
  .fieldContainer {
    margin-bottom: 1rem;
  }
  .fieldContainer.halfWidth {
    width: 100%;
    padding-right: 0;
  }
  .fieldContainer .fieldInputGroup.shortField {
    width: 40%;
  }
  .fieldContainer .fieldTextGroup.textAlignment {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 576px) {
  .fieldContainer {
    margin-bottom: 1rem;
  }
  .fieldContainer .fieldInputGroup .fieldInput {
    font-size: 1rem;
    padding: 0.625rem 0.75rem;
  }
  .fieldContainer .passwordIcon {
    font-size: 1.25rem;
    margin-left: -2rem;
  }
  .fieldContainer .fieldInputGroup.shortField {
    width: 100%;
  }
  .fieldContainer .fieldInputGroup .inputPrefix,
  .fieldContainer .fieldInputGroup .inputPostfix {
    font-size: 1rem;
  }
}
