.orderLabelContainer .headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.orderLabelForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.5rem;
}

.orderLabelForm .formSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 2rem;
  border: solid 1px #efefef;
  border-radius: 8px;
}

.orderLabelForm .formSection .sectionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderLabelForm .formSection .formRow {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.orderLabelForm .orderFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.orderLabelForm .orderFooter .btnContainer {
  display: flex;
  gap: 1rem;
}

.orderTotal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orderTotal.orderHeader {
  justify-content: space-between;
  padding-bottom: 1.5rem;
}

.orderTotal p {
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 0;
}

/* Bulk Order */
#bulkOrderContainer {
  max-width: 800px;
  margin: 2rem auto;
}

#bulkOrderContainer .dragdropContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: dashed 2px #efefef;
  border-radius: 0.25rem;
  width: 100%;
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

#bulkOrderContainer .dragdropContainer.active {
  background-color: #dfe9fd;
  border: dashed 2px #2c5ebb;
}

#bulkOrderContainer .dragdropContainer label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 300px;
  cursor: pointer;
}

#bulkOrderContainer .dragdropContainer svg {
  color: #a0b6df;
  width: 100%;
  height: 80px;
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

#bulkOrderContainer .dragdropContainer.active svg {
  color: #2c5ebb;
}

#bulkOrderContainer .dragdropContainer p {
  font-size: 1rem;
  font-weight: 300;
  margin: 1rem;
}

#bulkOrderContainer .dragdropContainer p span {
  text-decoration: underline;
  font-weight: 600;
  color: var(--primaryColor);
}

#bulkOrderContainer .dragdropContainer input[type="file"] {
  display: none;
}

#bulkOrderContainer .dragDropFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  color: #585858;
}

#bulkOrderContainer .bulkOrderTemplate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #f9f9f9;
  border: solid 1px #efefef;
  border-radius: 0.75rem;
}

#bulkOrderContainer .bulkOrderTemplate .instructions {
  display: flex;
  flex-direction: column;
  width: 70%;
  padding: 0.5rem 1rem 0.5rem 0;
}

#bulkOrderContainer .bulkOrderTemplate .instructions .instructionHeading {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 0.75rem;
}

#bulkOrderContainer .bulkOrderTemplate .instructions .instructionHeading img {
  width: 2.5rem;
  height: 100%;
  object-fit: contain;
}

#bulkOrderContainer .bulkOrderTemplate .instructions .instructionHeading h2 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#bulkOrderContainer .bulkOrderTemplate .instructions p {
  font-size: 1rem;
  font-weight: 300;
  margin: 0;
}

#bulkOrderContainer .bulkOrderTemplate .removeFileContainer {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 30%;
}

#bulkOrderContainer .bulkOrderTemplate a,
#bulkOrderContainer
  .bulkOrderTemplate
  .removeFileContainer
  input[type="button"] {
  font-family: "quiet-sans", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: black;
  background-color: white;
  padding: 0.75rem 1rem;
  border: solid 1px #e5e5e5;
  border-radius: 0.25rem;
  width: 100%;
  max-width: 150px;
  cursor: pointer;
}

#bulkOrderContainer .bulkOrderSubmit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1.5rem;
}

#bulkOrderContainer .bulkOrderSubmit h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
}

/* Order Success */
.orderSummary {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0.5rem 2rem;
  border: solid 1px #efefef;
  border-radius: 8px;
}

.orderSummary h2 {
  font-size: 1.5rem;
  font-weight: 600;
  width: 100%;
  color: var(--primaryColor);
}

.orderSummary .orderSummarySection {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.orderSummary .orderSummarySection h3 {
  font-size: 1.1rem;
  font-weight: 600;
  width: 100%;
  margin-bottom: 0.5rem;
}

.orderSummary .orderSummarySection .orderSummaryRow {
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 0.2rem;
  margin-bottom: 0.5rem;
}

.orderSummary .orderSummarySection .orderSummaryRow p {
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0;
  color: #575757;
}

.orderSummary .orderSummarySection .orderSummaryRow p strong {
  font-weight: 500;
  color: black;
}

.orderConfirmationPopup {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.orderConfirmationPopup .popupContainer {
  position: fixed;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 1.5rem;
  width: 35%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-transition: all ease-in-out 0.6s;
  -moz-transition: all ease-in-out 0.6s;
  -ms-transition: all ease-in-out 0.6s;
  -o-transition: all ease-in-out 0.6s;
  transition: all ease-in-out 0.6s;
}

.orderConfirmationPopup .popupContainer .closeBtn {
  padding: 0.5rem;
  cursor: pointer;
  width: 3rem;
  height: 3rem;
  color: black;
  align-self: end;
}

.orderConfirmationPopup.active {
  background: rgba(0, 0, 0, 0.7);
  z-index: 15;
}

.orderConfirmationPopup.active .popupContainer {
  top: 50%;
}

.orderConfirmationPopup .popupContainer h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-top: -1rem;
}

.orderConfirmationPopup .popupContainer p {
  font-size: 1.1rem;
  font-weight: 300;
  text-align: center;
  margin: 1rem 0;
}

.orderConfirmationPopup .popupContainer .orderConfirmBtnGroup {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.orderConfirmationPopup .popupContainer .orderConfirmBtnGroup button {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .orderLabelContainer {
    max-width: 100%;
    padding: 5rem;
  }
  .orderConfirmationPopup .popupContainer {
    width: 60%;
  }
  #bulkOrderContainer {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .orderLabelContainer {
    padding: 3rem;
  }
  .orderLabelContainer .formSection .formRow {
    flex-wrap: wrap;
    gap: 0;
  }
  .orderSummary .orderSummarySection .orderSummaryRow {
    width: 100%;
  }
  .orderConfirmationPopup .popupContainer {
    width: 80%;
    padding: 1rem;
  }
  #bulkOrderContainer {
    max-width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .orderLabelForm .formSection {
    padding: 0.5rem 1rem;
  }
  .orderLabelContainer {
    padding: 3rem 1rem;
  }
  .orderLabelForm .orderFooter {
    flex-direction: column;
    gap: 2rem;
  }
  .orderLabelForm .orderFooter .btnContainer {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  .orderLabelForm .orderFooter .btnContainer .kemLabelsBtn {
    width: 100%;
  }
  .orderLabelContainer .headingContainer h1 {
    font-size: 1.5rem;
  }
  .orderSummary {
    padding: 0.5rem 1rem;
  }
  .orderSummary h2 {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .orderConfirmationPopup .popupContainer {
    width: 90%;
  }
  .orderConfirmationPopup .popupContainer h1 {
    font-size: 1.5rem;
  }
  .orderConfirmationPopup .popupContainer p {
    font-size: 1rem;
  }
  .orderConfirmationPopup .popupContainer .closeBtn {
    width: 2.5rem;
    height: 2.5rem;
  }
  .orderTotal.orderHeader {
    flex-direction: column-reverse;
    gap: 1.5rem;
  }
  .orderTotal.orderHeader button {
    font-size: 1rem;
  }
  #bulkOrderContainer .bulkOrderTemplate {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }
  #bulkOrderContainer .dragDropFooter p {
    font-size: 0.9rem;
  }
  #bulkOrderContainer .bulkOrderTemplate .instructions,
  #bulkOrderContainer .bulkOrderTemplate a,
  #bulkOrderContainer .bulkOrderTemplate .removeFileContainer {
    width: 100%;
  }
  #bulkOrderContainer .bulkOrderTemplate .instructions {
    padding: 0.5rem 0;
  }
  #bulkOrderContainer .bulkOrderTemplate a {
    align-self: flex-start;
  }
  #bulkOrderContainer .bulkOrderTemplate .removeFileContainer {
    justify-content: left;
  }
  #bulkOrderContainer .bulkOrderSubmit h2 {
    font-size: 1.2rem;
  }
}
