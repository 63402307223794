.settingsContainer {
  display: flex;
  width: 100%;
  padding: 5rem 10rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  min-height: 83vh;
  flex-wrap: wrap;
  align-content: flex-start;
}

.settingsContainer h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 3rem;
  width: calc(80% - 2rem);
  margin-left: auto;
}

.settingsContainer .settingsDropdown {
  display: none;
  margin-bottom: 3rem;
  width: 100%;
}

.settingsContainer .settingsInnerContainer {
  display: flex;
  width: 100%;
  gap: 2rem;
}

.settingsContainer .settingsInnerContainer .settingsSidebar {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 20%;
}

.settingsContainer .settingsInnerContainer .settingsSidebar .link {
  font-size: 1.1rem;
  font-weight: 400;
  color: #2e2e2e;
}

.settingsContainer .settingsInnerContainer .settingsSidebar .link.active {
  color: var(--primaryColor);
}

.settingsContainer .settingsInnerContainer .settingsContent {
  background: white;
  padding: 2rem 3rem;
  width: 80%;
  border: solid 1px #efefef;
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0 rgba(180, 180, 180, 0.1);
}

.settingsContainer .settingsInnerContainer .settingsContent .headings h2 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.settingsContainer .settingsInnerContainer .settingsContent .headings p {
  font-size: 1.1rem;
  font-weight: 300;
  margin-bottom: 1rem;
}

.settingsContainer .settingsInnerContainer .settingsContent .headings p strong {
  color: var(--primaryColor);
  font-weight: 600;
}

.settingsContainer
  .settingsInnerContainer
  .settingsContent
  .settingsFieldContainer {
  display: flex;
  flex-direction: column;
}

.settingsContainer
  .settingsInnerContainer
  .settingsContent
  .settingsFieldContainer
  .settingsField {
  max-width: 500px;
}

.settingsContainer
  .settingsInnerContainer
  .settingsContent
  .settingsFieldContainer
  .otpContainer {
  justify-content: flex-start;
  margin: 1.5rem 0px 3rem;
}

.settingsContainer
  .settingsInnerContainer
  .settingsContent
  .settingsFieldContainer
  .btnGroup {
  display: flex;
  gap: 1.5rem;
}

@media screen and (max-width: 1200px) {
  .settingsContainer {
    padding: 5rem;
    max-width: 100%;
  }
}

@media screen and (max-width: 992px) {
  .settingsContainer h1 {
    margin-left: 0;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .settingsContainer .settingsInnerContainer .settingsSidebar {
    display: none;
  }
  .settingsContainer .settingsInnerContainer .settingsContent {
    width: 100%;
  }
  .settingsContainer .settingsDropdown {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .settingsContainer {
    min-height: auto;
    padding: 3rem;
  }
  .settingsContainer .settingsInnerContainer .settingsContent {
    padding: 1.5rem 2rem;
  }
}

@media screen and (max-width: 576px) {
  .settingsContainer {
    padding: 3rem 2rem;
  }
  .settingsContainer .settingsInnerContainer .settingsContent {
    padding: 0;
    border: none;
    box-shadow: none;
  }
  .settingsContainer .settingsInnerContainer .settingsContent .headings h2 {
    font-size: 1.3rem;
  }
  .settingsContainer .settingsInnerContainer .settingsContent .headings p {
    font-size: 1rem;
  }
  .settingsContainer
    .settingsInnerContainer
    .settingsContent
    .settingsFieldContainer
    .kemLabelsBtn {
    max-width: 100% !important;
  }
  .settingsContainer
    .settingsInnerContainer
    .settingsContent
    .settingsFieldContainer
    .otpContainer {
    justify-content: center;
  }

  .settingsContainer
    .settingsInnerContainer
    .settingsContent
    .settingsFieldContainer
    .otpContainer
    .otpInputContainer {
    width: 250px;
    height: 70px;
  }

  .settingsContainer
    .settingsInnerContainer
    .settingsContent
    .settingsFieldContainer
    .btnGroup {
    flex-direction: column;
  }
}
