.stripePaymentForm {
  width: 100%;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  padding: 40px;
}

.stripePaymentForm #payment-element {
  margin-bottom: 24px;
}

.stripePaymentForm .stripeFieldGroup {
  display: flex;
  gap: 0.75rem;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer {
  position: relative;
  margin-bottom: 0.75rem;
  vertical-align: top;
  font-family: BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  line-height: 1.15;
  color: #30313d;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer.emailField {
  width: 65%;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer.loadAmountField {
  width: 35%;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer .stripeFieldLabel {
  display: block;
  font-size: 0.93rem;
  margin-bottom: 0.25rem;
  -webkit-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -ms-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  -o-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer .stripeFieldInput {
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  display: block;
  width: 100%;
  padding: 0.75rem;
  border-radius: 5px;
  background-color: white;
  -webkit-transition: background-color 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease, color 0.15s ease;
  -moz-transition: background-color 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease, color 0.15s ease;
  -ms-transition: background-color 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease, color 0.15s ease;
  -o-transition: background-color 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease, color 0.15s ease;
  transition: background-color 0.15s ease, border 0.15s ease,
    box-shadow 0.15s ease, color 0.15s ease;
  border: solid 1px #e6e6e6;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02);
  appearance: none;
  filter: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  z-index: 2;
}

.stripePaymentForm
  .stripeFieldGroup
  .stripeFieldContainer
  .stripeFieldInput.disabled {
  background-color: #f2f2f2;
  color: #757680;
  border-color: #e6e6e6;
  box-shadow: none;
}

.stripePaymentForm
  .stripeFieldGroup
  .stripeFieldContainer
  .stripeFieldInput:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02),
    0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.stripePaymentForm
  .stripeFieldGroup
  .stripeFieldContainer
  .stripeFieldInput.prefix {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.stripePaymentForm
  .stripeFieldGroup
  .stripeFieldContainer
  .stripeFieldInput.postfix {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer .stripePrefix,
.stripePaymentForm .stripeFieldGroup .stripeFieldContainer .stripePostfix {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 0.5rem;
  color: #4b4b4b;
  background: #e0e0e0;
  border: solid 1px #e6e6e6;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer .stripePrefix {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right-width: 0;
}

.stripePaymentForm .stripeFieldGroup .stripeFieldContainer .stripePostfix {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left-width: 0;
}

.stripePaymentForm .totalCredsUpdate {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 300;
  margin: 2.5rem 0 4rem;
}

.stripePaymentForm .totalCredsUpdate strong {
  color: var(--primaryColor);
}

.stripePaymentForm .loadSuccessBtnGroup {
  display: flex;
  gap: 1.5rem;
}

@media screen and (max-width: 768px) {
  .stripePaymentForm .stripeFieldGroup {
    flex-direction: column;
    gap: 0;
  }
  .stripePaymentForm .stripeFieldGroup .stripeFieldContainer.emailField,
  .stripePaymentForm .stripeFieldGroup .stripeFieldContainer.loadAmountField {
    width: 100%;
  }
}

@media screen and (max-width: 592px) {
  .stripePaymentForm {
    box-shadow: none;
    padding: 0;
    min-width: initial;
  }

  .stripePaymentForm .loadSuccessBtnGroup {
    flex-direction: column-reverse;
    flex-wrap: wrap;
  }
}
