@import url("https://use.typekit.net/qsy2bkp.css");

:root {
  --primaryColor: #6942f5;
  --secondaryColor: #4f2dcf;
  --linkColor: #2b4e7b;
  --errorColor: #ff0033;
  --successColor: #00cc66;
  --infoColor: #0066ff;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "quiet-sans", sans-serif;
  margin: 0;
  padding: 0;
}

.wrapper {
  padding-top: 12vh;
}

.wrapper.navHidden {
  padding-top: 0;
}

.link {
  font-weight: 600;
  color: var(--primaryColor);
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.linkAlt {
  font-weight: 400;
  color: var(--linkColor);
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.link:hover,
.linkAlt:hover {
  opacity: 0.7;
}

.kemLabelsBtn {
  font-family: "quiet-sans", sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 12px;
  border: solid 2px var(--primaryColor);
  border-radius: 10px;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.kemLabelsBtn.solid {
  background-color: var(--primaryColor);
  color: white;
}

.kemLabelsBtn.outline {
  background-color: transparent;
  color: var(--primaryColor);
}

.kemLabelsBtn.solid:hover {
  opacity: 0.8;
}

.kemLabelsBtn.outline:hover {
  background-color: var(--primaryColor);
  color: white;
}

.kemLabelsBtn:disabled {
  background-color: #d7d7d7;
  border-color: #cbcbcb;
  color: #856ed7;
  opacity: 0.7;
}

.kemLabelsBtn:disabled:hover {
  background-color: #e6e6e6;
  border-color: #dbdbdb;
  color: #a68dff;
  opacity: 1;
}

.buttonSpinner {
  -ms-animation: btnSpinner 1s infinite linear;
  -moz-animation: btnSpinner 1s infinite linear;
  -webkit-animation: btnSpinner 1s infinite linear;
  -o-animation: btnSpinner 1s infinite linear;
  animation: btnSpinner 1s infinite linear;
}

@keyframes btnSpinner {
  from {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.floatingBtn {
  position: fixed;
  bottom: 3rem;
  right: 5%;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
  z-index: 2;
}

.floatingBtn.hidden {
  opacity: 0;
}

.globalContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 5rem 7rem;
  text-align: left;
}

.globalContainer .headingContainer {
  margin-bottom: 1.5rem;
}

.globalContainer .headingContainer h1 {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.globalContainer .headingContainer p {
  color: #696969;
}

.globalContainer h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin: 1rem 0;
}

.globalContainer p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.globalContainer ul {
  padding-left: 1.5rem;
  margin-bottom: 0.5rem;
}

.globalContainer ul li {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
}

.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding: 1rem;
  min-height: 5vh;
}

.footer p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1rem;
  opacity: 0.9;
}

.alertMessageContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.25rem;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.alertMessageContainer p {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.5rem;
  text-align: left;
  padding-left: 5px;
  margin-bottom: 2px;
  width: 100%;
}

.alertMessageContainer svg {
  width: 1.5rem;
  height: auto;
}

.alertMessageContainer.error {
  border: solid 1px var(--errorColor);
}

.alertMessageContainer.error p {
  color: var(--errorColor);
}

.alertMessageContainer.success {
  border: solid 1px var(--successColor);
}

.alertMessageContainer.success p {
  color: var(--successColor);
}

.alertMessageContainer.info {
  border: solid 1px var(--infoColor);
}

.alertMessageContainer.info p {
  color: var(--infoColor);
}

.dropdown.fullWidth {
  width: 100%;
}

.dropdown .dropdownControl {
  font-size: 1rem;
  font-weight: 300;
  color: black;
  padding: 0.75rem 52px 0.75rem 10px;
  border: solid 1px rgba(46, 48, 53, 0.2);
  border-radius: 4px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.dropdown .dropdownControl .dropdownArrow {
  top: 43%;
}

.dropdown .dropdownControl:hover {
  box-shadow: none;
}

.dropdown .dropdownMenu {
  border-color: #f0f0f0;
  border-radius: 0 0 8px 8px;
  z-index: 1;
}

.dropdown .dropdownMenu .Dropdown-option {
  font-size: 1rem;
  font-weight: 300;
  color: black;
  background-color: white;
  padding: 1rem;
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

.dropdown .dropdownMenu .Dropdown-option:hover {
  color: white;
  background-color: var(--primaryColor);
}

.dropdown .dropdownMenu .Dropdown-option.is-selected {
  color: white;
  background-color: var(--primaryColor);
}

.tableNoResults {
  text-align: center;
}

.tableNoResults p strong {
  font-weight: 600;
  color: var(--primaryColor);
}

.table {
  border-collapse: collapse;
}

.table thead tr {
  border-bottom: solid 1px #dadada;
}

.table thead th {
  text-align: left;
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.5rem 0.5rem 1rem;
  cursor: pointer;
}

.table thead th svg {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
}

.table tbody tr {
  border-bottom: solid 1px #f1f1f1;
}

.table tbody td {
  text-align: left;
  font-size: 1rem;
  font-weight: 300;
  line-height: 2rem;
  padding: 0.75rem 0.5rem;
}

.table thead th.alignRight,
.table tbody td.alignRight {
  text-align: right;
}

.tableHeaderActions {
  display: flex;
  gap: 1rem;
}

.tableHeaderActions .tableSearch {
  width: 70%;
}

.tableHeaderActions .tableSearch .searchField {
  max-width: 500px;
}

.tableHeaderActions .tableItemsToShow {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  width: 30%;
  margin-bottom: 1.5rem;
}

.tableHeaderActions .tableItemsToShow .itemsToShowLabel {
  font-size: 1rem;
  font-weight: 300;
  margin-top: -2px;
}

.tableFooterActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
}

.tableFooterActions .tablePaginationBtns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}

.tableFooterActions .tablePaginationBtns .paginationBtnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;
  border: none;
  background: transparent;
}

.tableFooterActions .tablePaginationBtns .paginationInput {
  font-family: "quiet-sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: black;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  outline: none;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #383838;
  margin: 1rem;
  animation: spin 2s linear infinite;
  transition: all 0.3s ease-in-out;
}

.loadingText {
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0.6;
  }
  50% {
    transform: rotate(180deg);
    opacity: 1;
  }
  100% {
    transform: rotate(360deg);
    opacity: 0.6;
  }
}

@media screen and (max-width: 1200px) {
  .table tbody td {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 992px) {
  .tableHeaderActions .tableSearch {
    width: 60%;
  }
  .tableHeaderActions .tableItemsToShow {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-top: 10vh;
  }
  .globalContainer {
    padding: 5rem;
  }
  .table tbody tr {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 1rem;
    border-color: rgba(46, 48, 53, 0.2);
  }
  .table tbody td {
    font-size: 1rem;
    padding: 0 0.5rem 0.25rem;
    width: 50%;
  }
  .tableHeaderActions {
    gap: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    padding-top: 12vh;
  }
  .globalContainer {
    padding: 5rem 2.5rem;
  }
  .footer p {
    font-size: 0.9rem;
  }
  .alertMessageContainer {
    padding: 1rem 0.5rem;
  }
  .alertMessageContainer p {
    font-size: 0.9rem;
  }
  .tableHeaderActions {
    flex-wrap: wrap;
    gap: 0;
    margin-bottom: 1.5rem;
  }
  .tableHeaderActions .tableItemsToShow,
  .tableHeaderActions .tableSearch {
    width: 100%;
  }
  .tableHeaderActions .tableItemsToShow {
    justify-content: space-around;
  }
  .tableFooterActions {
    justify-content: center;
    margin: 0 -1rem;
  }
  .tableFooterActions div:not(.tablePaginationBtns) {
    display: none;
  }
  .tableHeaderActions .tableItemsToShow .dropdown .dropdownControl {
    font-size: 1rem;
  }
  .tableHeaderActions .tableItemsToShow .itemsToShowLabel {
    font-size: 1rem;
  }
  .globalContainer h2 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 400px) {
  .wrapper {
    padding-top: 9vh;
  }
}
