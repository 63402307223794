.checkboxContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}

.checkboxContainer input {
  width: 1rem;
  height: 1rem;
  margin-top: 2px;
}
