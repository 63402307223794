.navbar {
  width: 100%;
  padding: 5vh 50px 0 50px;
  position: fixed;
  top: 0;
  margin-bottom: auto;
  z-index: 10;
}

.navbar.navHidden {
  display: none;
}

.navContainer {
  background-color: white;
  min-height: 50px;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  border-radius: 50px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.07);
}

.logo {
  text-decoration: none;
}

.logo img {
  width: 100%;
  height: 1.5rem;
}

.navLinksContainer {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.navLink {
  font-size: 1rem;
  font-weight: 400;
  color: black;
  text-decoration: none;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.navLink.active {
  color: var(--primaryColor);
  font-weight: 600;
}

.navLink:hover {
  opacity: 0.7;
}

.navCorner {
  display: none;
  height: auto;
  width: 1.5rem;
}

.mobileNav {
  display: none;
  align-self: center;
}

.mobNavLinksContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 3rem;
}

.mobNavLinksContainer hr {
  width: 100%;
  color: black;
  opacity: 0.3;
  margin: 0.25rem 0;
}

.mobNavLinksContainer .navLink {
  justify-content: space-between;
}

.mobNavLinksContainer .navLink .alignRight {
  margin-left: auto;
}

.mobContact {
  font-size: 1.2rem;
  margin: auto -2rem 0;
  padding: 1rem 2rem 0;
  border-top: solid 1px #efefef;
}

.mobContact .contactInfo {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  flex-wrap: wrap;
  gap: 1rem;
}

.mobContact .contactInfo a {
  display: flex;
  flex: 1;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.8);
  text-decoration: none;
}

.iconButton {
  border: none;
  color: black;
  background-color: transparent;
}

.hamburgerMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 85%;
  height: 100vh;
  background-color: white;
  padding: 3rem 2rem;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all ease-in-out 0.4s;
  -moz-transition: all ease-in-out 0.4s;
  -ms-transition: all ease-in-out 0.4s;
  -o-transition: all ease-in-out 0.4s;
  transition: all ease-in-out 0.4s;
  box-shadow: none;
  z-index: 5;
}

.hamburgerMenu.active {
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.accountIconContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.accountIconContainer:hover {
  opacity: 0.7;
}

.accountIconContainer .accountIcon {
  width: 1.75rem;
  height: 1.75rem;
}

.accountIconContainer .navLink {
  font-size: 1.1rem;
}

.accountIconContainer p {
  margin-top: -4px;
  padding: 0.5rem;
}

.accountIconContainer p:hover {
  opacity: 1;
}

.accountIconContainer .dropdownIcon {
  width: 1rem;
  height: 1rem;
  margin-top: 2px;
}

.dropdownContent {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 10px;
  position: absolute;
  right: 100px;
  top: 11vh;
  width: 320px;
  box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
  visibility: visible;
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: top right;
  -moz-transform-origin: top right;
  -ms-transform-origin: top right;
  -o-transform-origin: top right;
  transform-origin: top right;
  z-index: 2;
}

.dropdownContent.activateAnimation {
  animation: zoomFade 0.4s ease-in-out;
}

.dropdownContent.hidden {
  visibility: hidden;
  opacity: 0;
}

@keyframes zoomFade {
  0% {
    -webkit-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    -o-transform: scale(0.8);
    transform: scale(0.8);
  }

  50% {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

.dropdownContent .dropdownProfileDetails {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 1.5rem;
}

.dropdownContent .dropdownProfileDetails .profileDetailsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropdownContent .dropdownProfileDetails .profileDetailsRow.username {
  display: none;
}

.dropdownContent
  .dropdownProfileDetails
  .profileDetailsRow
  .profileDetailsLabel {
  font-size: 1rem;
  font-weight: 300;
  color: #454545;
}

.dropdownContent
  .dropdownProfileDetails
  .profileDetailsRow
  .profileDetailsValue {
  font-size: 1rem;
  font-weight: 400;
  color: black;
}

.dropdownContent hr {
  color: black;
  opacity: 0.3;
  margin: 0 1.5rem;
}

.dropdownContent .dropdownLink {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem 1.5rem;
  cursor: pointer;
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

.dropdownContent .dropdownLink div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.dropdownContent .dropdownLink div .dropdownNavLink {
  font-size: 1.1rem;
  font-weight: 400;
  color: black;
  text-decoration: none;
  margin-top: -2px;
}

.dropdownContent .dropdownLink:hover {
  background: var(--primaryColor);
}

.dropdownContent .dropdownLink:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdownContent .dropdownLink:hover * {
  color: white !important;
}

@media screen and (max-width: 900px) {
  .navContainer {
    padding: 20px 30px;
  }

  .navLinksContainer {
    gap: 2.5rem;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 3vh 20px 0 20px;
  }

  .navContainer {
    padding: 20px 30px;
  }

  .logo img {
    height: 1.25rem;
  }

  .navLinksContainer {
    gap: 1.7rem;
  }

  .accountIconContainer {
    gap: 0.5rem;
  }

  .accountIconContainer .navLink {
    display: none;
  }

  .dropdownContent .dropdownProfileDetails .profileDetailsRow.username {
    display: flex;
  }

  .dropdownContent {
    top: 9vh;
    right: 50px;
  }
}

@media screen and (max-width: 576px) {
  .navbar.navHidden {
    display: block;
  }

  .logo img {
    height: 1.5rem;
  }

  .navContainer {
    height: auto;
  }

  .navLinksContainer {
    display: none;
  }

  .navLink {
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1.1rem;
  }

  .navLink svg {
    width: 18px;
    height: 18px;
    margin-top: 5px;
  }

  .navLink span {
    padding-left: 1rem;
  }

  .navCorner {
    display: block;
  }

  .mobileNav {
    display: block;
  }
}

@media screen and (max-width: 400px) {
  .navbar {
    padding: 0;
  }

  .navContainer {
    padding: 20px;
    border-radius: 0;
    border-bottom: solid 1px #e5e5e5;
    box-shadow: none;
  }

  .hamburgerMenu {
    padding: 2.5rem 2rem;
  }
}
