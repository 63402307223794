.hero {
  background-color: var(--primaryColor);
  background-image: url("../../public/media/heroBG.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 7rem 5rem 3rem 5rem;
  min-height: 100vh;
  height: auto;
  display: flex;
  align-items: center;
}

.heroColumn {
  flex-direction: column;
  display: flex;
  width: 50%;
  padding: 1rem;
}

.heroColumn h1 {
  font-size: 2.75rem;
  font-weight: 600;
  color: white;
  margin-bottom: 1.5rem;
}

.heroColumn p {
  font-size: 1.2rem;
  font-weight: 300;
  color: white;
}

.homeSecContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10rem 5rem 3rem;
}

.homeSecContainer h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: black;
  text-align: center;
  margin-bottom: 1.5rem;
}

.homeSecContainer p {
  font-size: 1.2rem;
  font-weight: 300;
  color: black;
  text-align: center;
  margin-bottom: 2.5rem;
}

.cardsContainer {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 1.5rem 5rem;
}

.cardsContainer .card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background: white;
  padding: 1rem;
  width: 30%;
  border: solid 1px #efefef;
  border-radius: 0.75rem;
  box-shadow: 0px 0px 16px rgba(180, 180, 180, 0.1);
  -webkit-transition: all ease-in-out 0.1s;
  -moz-transition: all ease-in-out 0.1s;
  -ms-transition: all ease-in-out 0.1s;
  -o-transition: all ease-in-out 0.1s;
  transition: all ease-in-out 0.1s;
}

.cardsContainer .card:hover {
  border-bottom: solid 3px var(--primaryColor);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
}

.cardsContainer .card img {
  width: 100%;
  object-fit: contain;
  object-position: center center;
  height: 16rem;
}

.cardsContainer .card .cardContent h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  margin: 0.5rem 0;
}

.cardsContainer .card .cardContent p {
  font-size: 1rem;
  font-weight: 300;
  color: black;
  margin-bottom: 1rem;
}

.accordion {
  width: 80%;
  align-self: center;
}

.accordionItem {
  margin-bottom: 1rem;
  border-radius: 0.25rem;
}

.accordionHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  font-weight: 400;
  background-color: white;
  color: black;
  padding: 1.1rem;
  width: 100%;
  text-align: left;
  border: none;
  cursor: pointer;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all ease-in-out 0.3s;
  -moz-transition: all ease-in-out 0.3s;
  -ms-transition: all ease-in-out 0.3s;
  -o-transition: all ease-in-out 0.3s;
  transition: all ease-in-out 0.3s;
}

.accordionHeading:hover {
  opacity: 0.8;
}

.accordionPanel {
  font-size: 1rem;
  font-weight: 300;
  color: black;
  line-height: 2rem;
  padding: 1.5rem 1rem;
  animation: accordionPanelFadeIn 0.6s ease-in-out;
}

.accordionItem.active .accordionHeading {
  color: var(--primaryColor);
}

@keyframes accordionPanelFadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media screen and (max-width: 1200px) {
  .cardsContainer {
    padding: 1.5rem 0;
  }
}

@media screen and (max-width: 992px) {
  .heroColumn h1 {
    font-size: 2rem;
  }

  .accordion {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .hero {
    flex-direction: column;
    justify-content: center;
    padding: 7rem 3rem 3rem;
  }

  .heroColumn {
    padding: 0.5rem;
    width: 100%;
  }

  .heroColumn p {
    font-size: 1rem;
  }

  .heroColumn img {
    margin: auto;
    max-width: 400px;
  }

  .homeSecContainer {
    padding: 0 3rem 3rem;
  }

  .homeSecContainer h1 {
    font-size: 2rem;
    margin-top: 5rem;
  }

  .cardsContainer {
    flex-wrap: wrap;
  }

  .cardsContainer .card {
    flex-direction: row;
    width: 100%;
    padding: 1rem 1.5rem;
  }

  .cardsContainer .card.reverse {
    flex-direction: row-reverse;
  }

  .cardsContainer .card img {
    height: 10rem;
    width: 10rem;
  }

  .cardsContainer .card .cardContent {
    width: 100%;
    padding-left: 2rem;
  }

  .cardsContainer .card.reverse .cardContent {
    padding-left: 0;
    padding-right: 2rem;
  }

  .cardsContainer .card .cardContent h2,
  .cardsContainer .card .cardContent p {
    text-align: left;
  }
}

@media screen and (max-width: 576px) {
  .hero {
    min-height: 100vh;
    padding: 10rem 1.25rem 3rem;
  }

  .heroColumn:first-child {
    margin-bottom: 2rem;
  }

  .heroColumn h1 {
    font-size: 2rem;
  }

  .heroColumn p {
    font-size: 1.2rem;
  }

  .heroColumn img {
    max-width: 300px;
  }

  .homeSecContainer {
    padding: 0 1.25rem 5rem;
  }

  .homeSecContainer h1 {
    margin-top: 10rem;
  }

  .homeSecContainer p {
    font-size: 1rem;
  }

  .cardsContainer .card,
  .cardsContainer .card.reverse {
    flex-direction: column;
    width: 100%;
    padding: 1rem 1.5rem;
  }

  .cardsContainer .card img {
    width: 100%;
  }

  .cardsContainer .card .cardContent,
  .cardsContainer .card.reverse .cardContent {
    width: 100%;
    padding: 0;
  }

  .cardsContainer .card .cardContent h2,
  .cardsContainer .card .cardContent p {
    text-align: center;
  }

  .accordionHeading {
    font-size: 1.1rem;
    gap: 0.75rem;
  }

  .accordionHeading svg {
    width: 1.25rem;
  }
}

@media screen and (max-width: 400px) {
  .heroColumn h1 {
    font-size: 1.6rem;
  }

  .heroColumn p {
    font-size: 1rem;
  }
}
